
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // global-css-imports start
//  note: if you are modifying the CSS on these blocks, please also modify it on .storybook/preview.tsx
// CSS library import
import '@speechifyinc/voice-cloning-web/dist/index.css';
import 'nprogress/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'simplebar-react/dist/simplebar.min.css';
// local CSS import
import 'css/animations.css';
import 'css/askai.css';
import 'css/experience.css';
import 'css/fonts.css';
import 'css/main.css';
import 'css/normalize.css';
import 'css/tailwind.css';
import 'css/three-dots.min.css';
import { FullScreenPromptProvider } from 'components/mobileAppPrompts/FullScreenPromptProvider';
import { AppProps } from 'next/app';
import { useEffect } from 'react';
import withFaro from 'lib/observability/withFaro';
import { initAnalytics } from 'utils/analytics';
import { initMessageListener } from 'utils/extension';
import MainWrapper from 'wrappers/MainWrapper';
// global-css-imports end
const App = ({ Component, pageProps }: AppProps): JSX.Element => {
    useEffect(() => {
        initAnalytics();
        const removeMessageListener = initMessageListener();
        return () => {
            removeMessageListener();
        };
    }, []);
    return (<MainWrapper>
      <FullScreenPromptProvider>
        <Component {...pageProps}/>
      </FullScreenPromptProvider>
    </MainWrapper>);
};
const __Next_Translate__Page__19504eb21b1__ = withFaro(App);

    export default __appWithI18n(__Next_Translate__Page__19504eb21b1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  