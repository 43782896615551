import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { IToast } from 'interfaces';
import React, { Fragment, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import TrashIcon from 'assets/icons/trash';
import { useTranslation } from 'hooks/useTypedTranslation';
import { removeToast } from 'modules/toast/stores/actions';

import Loading from './Loading';

interface ToastProps {
  toast: IToast;
}

const ToastComponent: React.FC<ToastProps> = ({ toast }) => {
  const { t } = useTranslation('common');

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
    // ESLint: React Hook useEffect has a missing dependency: 'show'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);

      setTimeout(() => {
        removeToast(toast.id);
      }, 1000);
    }, toast.duration || 4500);

    return () => {
      clearTimeout(timer);
    };
    // ESLint: React Hook useEffect has missing dependencies: 'dispatch' and 'toast.duration'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast.id]);

  const handleClose = () => {
    setShow(false);
  };

  const renderIcon = () => {
    switch (toast.type) {
      case 'error':
        return <ExclamationCircleIcon className="size-6 text-red-400" aria-hidden="true" />;

      case 'gamification':
        return <span className="ml-3 size-6">🎉</span>;

      case 'info':
        return <InformationCircleIcon className="size-6 text-gray-600" aria-hidden="true" />;

      case 'trash':
        return <TrashIcon className="size-6" />;

      case 'waiting':
        return <Loading size={20} />;

      case 'warning':
        return <ExclamationCircleIcon className="size-6 text-yellow-500" aria-hidden="true" />;

      default:
        return <CheckCircleIcon className="size-6 text-green-400" aria-hidden="true" />;
    }
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="-translate-y-20 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in duration-200 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="-translate-y-20 opacity-0"
    >
      <div
        className={twMerge(
          'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5', //
          toast.type === 'gamification' && 'bg-glass-200 shadow-300'
        )}
      >
        <div className="p-2">
          <div className="flex items-start">
            <div className="flex-shrink-0">{renderIcon()}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {toast.title && <p className="mb-1 text-sm font-medium text-gray-900">{toast.title}</p>}
              {toast.description && <p className="text-sm text-gray-500">{toast.description}</p>}
              {toast.action && toast.onAction ? (
                <p className="mb-2 mt-2 text-sm text-gray-500">
                  <button
                    className="text-electric-400"
                    onClick={() => {
                      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                      toast.onAction();
                      handleClose();
                    }}
                  >
                    {toast.action}
                  </button>
                </p>
              ) : null}
            </div>
            <div className="ml-4 flex flex-shrink-0 pt-0.5">
              <button className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={handleClose}>
                <span className="sr-only">{t('Close')}</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ToastComponent;
