import { IToast } from 'interfaces';

import useToastStore from '../toastStore';

export const addToast = (payload: Omit<IToast, 'id'> & Partial<Pick<IToast, 'id'>>) => {
  const state = useToastStore.getState();
  useToastStore.setState({ id: state.id + 1, toasts: [...state.toasts, { ...payload, id: payload.id ?? state.id }] });
};

export const removeToast = (payload: number | string) => {
  const state = useToastStore.getState();
  useToastStore.setState({ toasts: state.toasts.filter(t => t.id !== payload) });
};
