import { AnalyticsBrowser } from '@segment/analytics-next';
import { RecordType } from 'interfaces';

import { logError, logEventInObservability } from 'lib/observability';

import pkg from '../../package.json';
import { browserNameAndVersion } from './browser';
import { getOptionalStringEnv, getRequiredStringEnv } from './safeEnvParsing';

let segment: AnalyticsBrowser | undefined;
let userId: string | undefined;

const segmentWriteKey = getOptionalStringEnv('NEXT_PUBLIC_SEGMENT_WRITEKEY');
const segmentProxyApiHost = getOptionalStringEnv('NEXT_PUBLIC_SEGMENT_PROXY_API_HOST');

export const initAnalytics = () => {
  if (typeof window === 'undefined') return;

  if (segmentWriteKey === undefined || segmentProxyApiHost === undefined) {
    console.warn('Segment is not initialized because of missing env variables');
    return;
  }

  segment = AnalyticsBrowser.load(
    {
      writeKey: segmentWriteKey,
      cdnURL: getOptionalStringEnv('NEXT_PUBLIC_SEGMENT_PROXY_CDN_HOST')
    },
    {
      integrations: {
        'Segment.io': {
          apiHost: segmentProxyApiHost
        }
      }
    }
  );
};

export const getSource = (pathname: string) => {
  let source = '';

  switch (pathname) {
    case '/':
      source = 'library';
      break;

    case '/item/[id]':
      source = 'listening screen';
      break;

    default:
    // ignore
  }

  return source;
};

function logEvent(eventName: string, data: $TSFixMe = {}) {
  try {
    segment?.track(eventName, data);
  } catch (e) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(e);
  }
}

/**
 * @deprecated Use logAnalyticsEvent from 'modules/analytics/logAnalyticsEvent' instead.
 * This provides better type safety and consistent analytics tracking.
 * @example
 * import { logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
 * logAnalyticsEvent(AnalyticsEventKey.YOUR_EVENT, { your: 'properties' });
 */
export const logSegmentEvent = (eventName: string, data: $TSFixMe = {}) => {
  // append information to make analytics consistent with extension
  const [browserName, browserVersion] = browserNameAndVersion('/').split('/');
  const eventData = {
    ...data,
    hostname: window.location.hostname,
    sdkVersion: pkg.dependencies['@speechifyinc/multiplatform-sdk'],
    usingSDK: true,
    webpage: window.location.href,
    browserName,
    browserVersion,
    webAppVersion: pkg.version
  };

  logEventInObservability(eventName, data);

  const isDev = getRequiredStringEnv('NODE_ENV') === 'development';
  const disableNoisyConsoleLog = getOptionalStringEnv('NEXT_PUBLIC_DEV_DISABLE_NOISY_CONSOLE_LOG');

  if (!isDev) {
    logEvent(eventName, eventData);
  } else {
    !disableNoisyConsoleLog && console.debug('segment', eventName, JSON.stringify(eventData));
  }
};

/**
 * Internal function for SDK analytics tracking. This function should ONLY be used by
 * src/lib/speechify/adaptors/analytics.ts and should not be called directly from other files.
 *
 * @internal
 * @param eventName - The name of the analytics event to track
 * @param data - Event properties that conform to Segment's track method parameters
 * @see WebAnalyticsEventsTrackerAdapter in src/lib/speechify/adaptors/analytics.ts
 */
export const FOR_SDK_USE_ONLY_logSegmentEvent = (eventName: string, data: Parameters<AnalyticsBrowser['track']>[1]) => {
  segment?.track(eventName, data);
};

export const segmentIdentifyByUserId = (uid: string) => {
  const isDev = getRequiredStringEnv('NODE_ENV') === 'development';
  if (!isDev && uid) {
    userId = uid;
    segment?.identify(uid);
  }
};

export const segmentIdentifyWithProps = (properties: $TSFixMe) => {
  const isDev = getRequiredStringEnv('NODE_ENV') === 'development';
  if (!isDev) {
    segment?.identify(userId, properties);
  }
};

export const setTimeToPlay = (mode: string, recordType: RecordType | string) => {
  localStorage.setItem('timeToPlay', JSON.stringify({ mode, recordType, startTime: new Date().getTime() }));
};
