import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import * as ct from 'countries-and-timezones';

import { getRequiredStringEnv } from 'utils/safeEnvParsing';

import { ConsoleInstrumentation } from './ConsoleInstrumentation';

type InitParams = {
  isolate?: boolean;
  isGrafanaSpeechifyHosted?: boolean;
};

export const DUMMY_SESSION_ID = 'anonymous';

export const initFaro = (collectorUrl: string, { isGrafanaSpeechifyHosted = false, isolate = false }: InitParams) => {
  const faro = initializeFaro({
    url: collectorUrl,
    instrumentations: [
      ...getWebInstrumentations({
        // we use custom console instrumentation to log errors as exceptions
        captureConsole: false,
        enablePerformanceInstrumentation: false
      }),
      new ConsoleInstrumentation()
    ],
    app: {
      name: 'Speechify Web App',
      version: getRequiredStringEnv('version')
    },
    isolate,
    beforeSend: event => {
      if (event.meta.session) {
        event.meta.session.id = DUMMY_SESSION_ID;

        if (event.meta.session.attributes) {
          event.meta.session.attributes.previousSession = DUMMY_SESSION_ID;
        }
      }

      return event;
    }
  });

  if (isGrafanaSpeechifyHosted) {
    // Wrapping it in a try/catch in case Intl is not available (old browsers)
    try {
      const session = faro.api.getSession();

      faro.api.setSession({
        ...session,
        attributes: {
          ...(session?.attributes || {}),
          country: ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id || '--'
        }
      });
    } catch (e) {
      console.error(e);
    }
  } else {
    faro.api.setSession({ id: DUMMY_SESSION_ID });
  }

  faro.api.pushLog(['Faro initialized']);

  return faro;
};
