import type { SpeechifyPersistedStoreState } from 'store';
import { StateCreator, StoreApi, create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { SPEECHIFY_VOICE_STORE_NAME } from 'modules/voices/utils/constants';
import { REDUX_IDB_DB_NAME, REDUX_IDB_OBJECT_STORE_NAME } from 'store/constants';
import { withTimeout } from 'utils/promise';

import { IDB_DATABASE_NAME } from '../utils/constants';
import { forceMigrationOnInitialPersist } from '../utils/forceMigrationOnInitialPersist';
import { nestedJsonParse } from '../utils/parse';
import { IndexedDBStorage } from '../utils/storage';
import { PersistentStoreOptions } from './types';

interface IndexedDbStoreOptions<T, PersistedState> extends PersistentStoreOptions<T, PersistedState> {
  storageName: string;
  backfillStateFromReduxPersist?: (reduxPersistedState: SpeechifyPersistedStoreState, defaultState: T) => T;
}

export type IndexedDbStore<State> = {
  (): State;
  <PartialState>(selector: (state: State) => PartialState): PartialState;
  getState: StoreApi<State>['getState'];
  setState: StoreApi<State>['setState'];
  subscribe: StoreApi<State>['subscribe'];
  waitForInitialHydration: () => Promise<void>;
  registerOnResetCleanup: (fn: () => void) => void;
  reset: () => void;
};

export function createIndexedDbStore<T, PersistedState = T>(initialState: StateCreator<T>, options: IndexedDbStoreOptions<T, PersistedState>) {
  if (options.version && options.version <= 0) {
    throw new Error('IndexedDbStore: version must be greater than 0');
  }

  if (!options.storageName) {
    throw new Error('IndexedDbStore: storageName is required');
  }

  const { backfillStateFromReduxPersist } = options;

  const store = create(
    persist(initialState, {
      skipHydration: typeof window === 'undefined', // skip hydration on SSR
      name: options.storageName,
      storage: forceMigrationOnInitialPersist(
        createJSONStorage(
          () =>
            new IndexedDBStorage(
              options.storageName,
              // This special case is for maintaining backwards compatibility for voice store which uses IDB_DATABASE_NAME as the database name in production.
              // In the past, we would use the same database name for all stores, but due to how IndexedDB migration works, we can't have multiple object stores with the same name in the same database,
              // since it would not trigger the `createObjectStore` callback and the database version would not be bumped. (see the IndexedDBStorage constructor)
              // This bug was discovered in WEB-5852 when we introduced the 2nd store that uses IndexedDB which is the new overhaul library store
              options.storageName === SPEECHIFY_VOICE_STORE_NAME ? IDB_DATABASE_NAME : options.storageName
            )
        )
      ),
      version: options.version ?? 1,
      migrate: async (state, version, ...args) => {
        if (version === 0 && typeof backfillStateFromReduxPersist === 'function') {
          try {
            const speechifyWebDb = new IndexedDBStorage(REDUX_IDB_OBJECT_STORE_NAME, REDUX_IDB_DB_NAME);
            const persistedReduxStateJsonString = (await speechifyWebDb.getItem('persist:root')) || '{}';
            const persistedReduxRootState = nestedJsonParse(persistedReduxStateJsonString) as SpeechifyPersistedStoreState;
            const backfilledState = backfillStateFromReduxPersist(persistedReduxRootState, state as T);
            return backfilledState;
          } catch (e) {
            console.error('Failed to backfill state from redux persist', e);
            return (options.migrate && options.migrate(state, version, ...args)) || state;
          }
        }
        return (options.migrate && options.migrate(state, version, ...args)) || state;
      },
      partialize: options.partialize ?? (state => state)
    })
  );

  let isWaitForInitialHydrationCalled = false;
  const waitForInitialHydration = async () => {
    isWaitForInitialHydrationCalled = true;
    return withTimeout(
      new Promise<void>(resolve => {
        if (store.persist.hasHydrated()) {
          resolve();
          return;
        }
        const cleanUp = store.persist.onFinishHydration(() => {
          cleanUp();
          resolve();
        });
      }),
      5000
    );
  };

  const useStore = <U>(selector: (state: T) => U) => {
    if (!isWaitForInitialHydrationCalled) {
      console.warn('useStore: waitForInitialHydration must be called before calling useStore');
    }
    return store(selector ?? (state => state));
  };

  const subscribe = (listener: (state: T, prevState: T) => void) => {
    if (!isWaitForInitialHydrationCalled) {
      console.warn('subscribe: waitForInitialHydration must be called before calling subscribe');
    }
    return store.subscribe(listener);
  };

  const getState = () => {
    if (!isWaitForInitialHydrationCalled) {
      console.warn('getState: waitForInitialHydration must be called before calling getState');
    }
    return store.getState();
  };

  let cleanupFunctions: (() => void)[] = [];

  const registerOnResetCleanup = (fn: () => void) => {
    cleanupFunctions.push(fn);
  };

  const reset = () => {
    cleanupFunctions.forEach(fn => fn());
    store.persist.rehydrate();
    cleanupFunctions = [];
  };

  Object.assign(useStore, { ...store, getState, subscribe, waitForInitialHydration, reset, registerOnResetCleanup });

  return useStore as IndexedDbStore<T> & {
    persist: (typeof store)['persist'];
  };
}
